<template>
  <div>
    <pagination
        v-model="page"
        :list="users.list"
        :pages="users.pages"
        @input="loadList"
    >
      <template #header>
        <search
            class="d-inline-block col-12 col-sm-6 col-md-5 col-lg-4"
            v-model="query"
            @input="search"
        />
        <multi-upload
            v-model="multiUploadDialog"
        />
        <v-btn
            outlined
            color="primary"
            class="ma-3 float-right"
            @click="editCustomer({}, {})"
        >
          Новый клиент
        </v-btn>
        <p>Найдено {{users.total}} пользователей.</p>
      </template>
      <template #item="{ item }">
        <v-list-item>
          <v-list-item-avatar>
            <v-icon v-if="Object.keys(item.role || {}).length">mdi-account-tie</v-icon>
            <v-icon v-if="!Object.keys(item.role || {}).length">mdi-account-circle-outline</v-icon>
          </v-list-item-avatar>
          <v-list-item-content class="box-list">
            <v-list-item-title>
              <div
                  class="primary--text text-decoration-underline cursor-pointer"
                  @click="edit(item)"
              >
                {{ (item.firstname || '').trim() || (item.company || '').trim() || "Нет имени" }}
              </div>
              <v-list-item-subtitle v-if="item.company">{{ item.company }}</v-list-item-subtitle>
            </v-list-item-title>
            <v-list-item-subtitle>id: {{ item.store_id }}</v-list-item-subtitle>
            <v-list-item-subtitle>{{ item.email }}</v-list-item-subtitle>
            <v-list-item-subtitle>+{{ item.phone }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <pr-menu
                offset-y
                :list="facilityList.map(facility => ({code: facility.facility_code, name: facility.name}))"
                @change="text(item.id, $event)"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                    icon
                    v-on="on"
                    v-bind="attrs"
                >
                  <v-icon>mdi-phone-message</v-icon>
                </v-btn>
              </template>
            </pr-menu>
          </v-list-item-action>
        </v-list-item>
      </template>
    </pagination>
    <dialog-card
        v-model="dialog"
        max-width="600px"
        min-height="90vh"
    >
      <template #title>
        {{ (customer.company || '').trim() || (customer.firstname || '').trim() || "Нет имени" }}
      </template>
      <template #actions>
        <v-btn
            color="primary"
            text
            @click="dialog = false"
        >
          Закрыть
        </v-btn>
      </template>
      <template #subtitle>
        <v-tabs v-model="tab">
          <v-tab>Личные данные</v-tab>
          <v-tab>Инвойсы</v-tab>
          <v-tab>Закрывашки</v-tab>
          <v-tab v-if="user.role.admin">Права</v-tab>
        </v-tabs>
      </template>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <div>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                    v-if="user.role.admin || user.role.receipt || user.role.receipt_manager"
                    v-on="on"
                    color="primary"
                    icon
                    @click="editCustomerDisclaimerDialog = true"
                >
                  <v-icon>mdi-account-edit</v-icon>
                </v-btn>
              </template>
              Редактировать
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                    v-if="user.role.admin || user.role.receipt || user.role.receipt_manager"
                    v-on="on"
                    color="primary"
                    icon
                    @click="editCustomerEmailDisclaimerDialog = true; changeEmailAddress = customer.email"
                >
                  <v-icon>mdi-email-edit</v-icon>
                </v-btn>
              </template>
              Изменить email
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                    v-on="on"
                    color="primary"
                    icon
                    @click="showPds()"
                    :loading="loading"
                    :disabled="customerPds.user_id || loading"
                >
                  <v-icon>mdi-account-key</v-icon>
                </v-btn>
              </template>
              Загрузить ПД
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on }">
                <v-btn
                    v-if="user.role.admin || user.role.receipt || user.role.receipt_manager"
                    v-on="on"
                    color="primary"
                    icon
                    @click="mask()"
                    :loading="loading"
                    :disabled="loading"
                >
                  <v-icon>mdi-incognito</v-icon>
                </v-btn>
              </template>
              Замаскироваться
            </v-tooltip>
          </div>
          <v-divider/>
          <div class="py-3 user-card">
            <div class="text--secondary py-2" v-if="customer.company">{{ customer.company }}</div>
            <div class="text--secondary py-2" v-if="customer.company">{{ customer.vat }}</div>
            <div class="py-2">{{ (customer.firstname || '').trim() || "Нет имени" }}</div>
            <div class="text--secondary py-2">id: {{ customer.store_id }}</div>
            <div class="text--secondary">
              <div class="d-inline-block py-2 pr-1">паспорт:</div>
              <span v-if="customerPds.data">{{ customerPds.data.passport_serial }}&nbsp;{{ customerPds.data.passport_number }}</span>
            </div>
            <div class="text--secondary">
              <div class="d-inline-block py-2 pr-1">адрес:</div>
              <span v-if="customerPds.address">{{ customerPds.address.country }},&nbsp;{{ customerPds.address.city }}, {{ customerPds.address.street }}</span>
            </div>
            <div class="py-2">{{ customer.email }}</div>
            <div class="py-2">+{{ customer.phone }}</div>
            <div class="py-2">
              <v-checkbox
                  label="Согласен на получение рекламы"
                  disabled
                  :value="customer.marketing||false"
              />
            </div>
          </div>
        </v-tab-item>
        <v-tab-item>
          <div v-if="invoiceLoading" class="font-italic text--disabled user-card py-4">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
            Идет загрузка инвойсов…
          </div>
          <div v-else>
            <div v-if="customerInvoices.length" class="user-card">
              <v-list>
                <v-list-item
                    v-for="item in customerInvoices"
                    :key="item.id"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ item.invoiceNumber }}</v-list-item-title>
                    <v-list-item-subtitle>{{ item.id }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-btn
                      icon
                      @click="share(item.siteCode, item.invoiceId)"
                  >
                    <v-icon>mdi-share-variant</v-icon>
                  </v-btn>
                </v-list-item>
              </v-list>
            </div>
            <div v-else class="user-card">
              <p class="py-4">Нет неоплаченных инвойсов</p>
            </div>
          </div>
          <v-snackbar
              v-model="clipboardToast"
              timeout="2000"
          >
            Ссылка скопирована в буфер обмена
          </v-snackbar>
        </v-tab-item>
        <v-tab-item>
          <div v-if="documentsLoading" class="font-italic text--disabled user-card py-4">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
            Идет загрузка документов…
          </div>
          <div v-else>
            <div v-if="customerDocuments.length" class="user-card">
              <v-list>
                <v-list-item
                    v-for="item in customerDocuments"
                    :key="item.id"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ item.filename }}</v-list-item-title>
                    <v-list-item-subtitle>{{ date.isoToHuman(item.created_at) }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-btn
                      icon
                      @click="deleteDocument(item.id)"
                  >
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </v-list-item>
              </v-list>
            </div>
            <div v-else class="user-card">
              <p class="py-4">Нет загруженных документов</p>
            </div>
            <div class="d-flex align-center">
              <v-file-input
                  v-model="files"
                  label="Документы"
                  small-chips
                  multiple
              />
              <v-btn
                  color="primary"
                  icon
                  :disabled="!files.length"
                  @click="addDocuments"
              >
                <v-icon>mdi-upload</v-icon>
              </v-btn>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item v-if="user.role.admin">
          <v-form ref="form" class="user-card">
            <v-subheader>Роли пользователя</v-subheader>
            <v-checkbox
                label="Администратор"
                v-model="customerRoles.admin"
            ></v-checkbox>
            <v-checkbox
                label="Контент-менеджер"
                v-model="customerRoles.seo"
            ></v-checkbox>
            <v-checkbox
                label="Бухгалтер"
                v-model="customerRoles.buh"
            ></v-checkbox>
            <v-checkbox
                label="Старший ресепшен"
                v-model="customerRoles.receipt_manager"
            ></v-checkbox>
            <v-checkbox
                label="Ресепшен"
                v-model="customerRoles.receipt"
            ></v-checkbox>
            <v-text-field
                v-if="customerRoles.receipt"
                v-model="facilities"
                label="Склады"
            ></v-text-field>
          </v-form>
          <div class="pt-5">
            <v-btn
                color="primary"
                outlined
                @click="request"
                :loading="loading"
                :disabled="loading"
            >
              Сохранить
            </v-btn>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </dialog-card>
    <dialog-card max-width="500px" v-model="keyDialog">
      <template #title>
        Ключ шифрования
      </template>
      <v-textarea v-model="key"></v-textarea>
      <template #actions>
        <v-btn
            color="primary"
            outlined
            @click="saveKey"
        >
          Сохранить
        </v-btn>
        <v-btn
            color="primary"
            text
            @click="keyDialog = false"
        >
          Закрыть
        </v-btn>
      </template>
    </dialog-card>
    <v-dialog max-width="300px" v-model="textConfirmDialog">
      <v-card>
        <v-card-title>Отправка пин-кода</v-card-title>
        <v-card-text>
          {{ textConfirm }}
        </v-card-text>
        <v-divider></v-divider>
        <v-btn
            class="pa-6"
            color="primary"
            text
            @click="textConfirmDialog = false"
        >
          Хорошо
        </v-btn>
      </v-card>
    </v-dialog>
    <dialog-card max-width="500px" v-model="changeEmailDialog">
      <template #title>
        Смена email-адреса
      </template>
      <v-text-field v-model="changeEmailAddress"/>
      <template #actions>
        <v-btn
            color="primary"
            outlined
            @click="changeEmail"
            :loading="loading"
            :disabled="loading"
        >
          Сохранить
        </v-btn>
        <v-btn
            color="primary"
            text
            @click="changeEmailDialog = false"
        >
          Закрыть
        </v-btn>
      </template>
    </dialog-card>
    <dialog-card max-width="300px" v-model="editCustomerDisclaimerDialog">
      <template #title>
        Внимание
      </template>
      <div>
        Изменения в личные данные вносятся только после официального уведомления клиентом посредством письменного заявления или сообщения
        с указанного в договоре номера телефона или электронной почты.
      </div>
      <template #actions>
        <v-btn
            color="primary"
            @click="editCustomerDisclaimerDialog = false; editCustomer(customer, customerPds)"
            :loading="loading"
            :disabled="loading"
            text
        >
          Подтверждаю
        </v-btn>
        <v-btn
            color="primary"
            outlined
            text
            @click="editCustomerDisclaimerDialog = false"
        >
          Закрыть
        </v-btn>
      </template>
    </dialog-card>
    <dialog-card max-width="300px" v-model="editCustomerEmailDisclaimerDialog">
      <template #title>
            Внимание
        </template>
        <div>
            Изменения в личные данные вносятся только после официального уведомления клиентом посредством письменного заявления или сообщения
            с указанного в договоре номера телефона или электронной почты.
        </div>
        <v-checkbox
                v-model="editCustomerEmailDisclaimerConfirm"
                label="Подтверждаю, что почта изменена в Store365"
        />
        <template #actions>
            <v-btn
                    color="primary"
                    @click="editCustomerEmailDisclaimerDialog = false; changeEmailDialog = true; editCustomerEmailDisclaimerConfirm = false"
                    :disabled="!editCustomerEmailDisclaimerConfirm"
                    text
            >
                Подтверждаю
            </v-btn>
            <v-btn
                    color="primary"
                    outlined
                    text
                    @click="editCustomerEmailDisclaimerDialog = false; editCustomerEmailDisclaimerConfirm = false"
            >
                Закрыть
            </v-btn>
        </template>
    </dialog-card>
    <dialog-card max-width="700px" v-model="editCustomerDialog">
      <template #title>
        <div v-if="customerIsNew">Создание пользователя</div>
        <div v-else>Редактирование пользователя</div>
      </template>
      <v-form ref="editCustomerForm">
        <pr-select
            v-model="customerBaseFacility"
            label="Адрес склада"
            no-data-text="Выберите склад"
            :items="facilityList"
            item-value="facility_code"
            item-text="name"
            required
            :rules="[v => !!v || 'Обязательное поле']"
        />
        <div class="d-flex align-center">
          <v-radio-group
              :row="!$vuetify.breakpoint.xsOnly"
              :column="$vuetify.breakpoint.xsOnly"
              v-model="customerType"
              mandatory
              :disabled="!customerIsNew"
          >
            <v-radio label="Физическое лицо" value="Физлицо"></v-radio>
            <v-radio label="Юридическое лицо" value="OOO"></v-radio>
            <v-radio label="ИП" value="ИП"></v-radio>
          </v-radio-group>
          <v-btn text color="primary" x-small v-if="!customerIsNew" @click="changeCompanyType">Изменить тип</v-btn>
        </div>
        <div v-if="customerType !== 'Физлицо'">
          <v-text-field
              v-model="customerCompany"
              label="Название компании"
              required
              :rules="[v => !!v && !!v.trim() || 'Обязательное поле']"
          />
          <v-text-field
              v-model="customerVat"
              label="ИНН"
              required
              :rules="[v => !!v && !!v.trim() || 'Обязательное поле']"
          />
        </div>
        <v-text-field
            v-model="customerName"
            label="Фамилия, имя, отчество"
            required
            :rules="[v => !!v && v.trim().split(' ').length > 1 || 'Укажите ФИО']"
        />
        <div v-if="customerType === 'Физлицо'">
          <v-subheader>Паспорт</v-subheader>
          <v-text-field
              class="col-4 d-inline-block pa-0"
              v-model="customerPassportSerial"
              label="Серия"
              :required="customerIsNew"
              :rules="[v => !!v && !!v.trim() || !customerIsNew || 'Обязательное поле']"
          />
          <v-text-field
              class="col-8 d-inline-block pa-0"
              v-model="customerPassportNumber"
              label="Номер"
              :required="customerIsNew"
              :rules="[v => !!v && !!v.trim() || !customerIsNew || 'Обязательное поле']"
          />
        </div>
        <v-subheader>Адрес</v-subheader>
        <v-text-field
            class="col-3 d-inline-block pa-0"
            v-model="customerCountry"
            label="Страна"
            :required="customerIsNew"
            :rules="[v => !!v && !!v.trim() || !customerIsNew || 'Обязательное поле']"
        />
        <v-text-field
            class="col-3 d-inline-block pa-0"
            v-model="customerCity"
            label="Город"
            :required="customerIsNew"
            :rules="[v => !!v && !!v.trim() || !customerIsNew || 'Обязательное поле']"
        />
        <v-text-field
            class="col-6 d-inline-block pa-0"
            v-model="customerStreet"
            label="Улица"
            :required="customerIsNew"
            :rules="[v => !!v && !!v.trim() || !customerIsNew || 'Обязательное поле']"
        />
        <v-text-field-phonemask
            v-model="customerPhone"
            label="Телефон"
            v-bind:options="{
                  inputMask: '+# (###) ###–##–##',
                  outputMask: '###########',
                  countryCode: '7',
                  empty: null,
                  applyAfter: false,
                  alphanumeric: false,
                  lowerCase: false,
                }"
            type="tel"
            required
            :rules="[v => !!v || v && !!v.trim() || 'Обязательное поле', v => v && v.length === 18 || 'Неверный формат']"
        />
        <v-text-field
            v-model="customerEmail"
            type="email"
            label="e-mail"
            required
            :disabled="!customerIsNew"
            :rules="[v => !!v && !!v.trim() || 'Обязательное поле']"
        />
        <v-checkbox
            label="Согласен на получение рекламы"
            v-model="customerMarketing"
        />
      </v-form>
      <template #actions>
        <v-btn
            color="primary"
            outlined
            @click="updateCustomer"
            :loading="loading"
            :disabled="loading"
        >
          Сохранить
        </v-btn>
        <v-btn
            color="primary"
            text
            @click="editCustomerDialog = false"
        >
          Закрыть
        </v-btn>
      </template>
    </dialog-card>
    <v-dialog max-width="360px" v-model="changeCompanyForbiddenDialog">
      <v-card>
        <v-card-title>Смена типа пользователя</v-card-title>
        <v-card-text>
          Для изменения типа клиента обратитесь, пожалуйста, к старшим администраторам
        </v-card-text>
        <v-divider></v-divider>
        <v-btn
            class="pa-6"
            color="primary"
            text
            @click="changeCompanyForbiddenDialog = false"
        >
          Понятно
        </v-btn>
      </v-card>
    </v-dialog>
    <dialog-card max-width="300px" v-model="changeCompanyTypeDisclaimerDialog">
      <template #title>
        Внимание
      </template>
      <div>
        Подтвердите, что
        <ul>
          <li>с клиентом было подписано дополнительное соглашение о смене сторон в договоре аренды;</li>
          <li>тип клиента уже изменен в Стор.</li>
        </ul>
      </div>
      <template #actions>
        <v-btn
            color="primary"
            @click="changeCompanyTypeDisclaimerDialog = false; changeCompanyTypeDialog = true"
            text
        >
          Подтверждаю
        </v-btn>
        <v-btn
            color="primary"
            outlined
            text
            @click="changeCompanyTypeDisclaimerDialog = false"
        >
          Закрыть
        </v-btn>
      </template>
    </dialog-card>
    <dialog-card max-width="700px" v-model="changeCompanyTypeDialog">
      <template #title>
        Смена типа пользователя
      </template>
      <v-form ref="editCustomerCompanyTypeForm">
        <div>
          <v-radio-group
              :row="!$vuetify.breakpoint.xsOnly"
              :column="$vuetify.breakpoint.xsOnly"
              v-model="changeCustomerType"
              mandatory
          >
            <v-radio label="Физическое лицо" value="Физлицо"></v-radio>
            <v-radio label="Юридическое лицо" value="OOO"></v-radio>
            <v-radio label="ИП" value="ИП"></v-radio>
          </v-radio-group>
        </div>
        <div v-if="changeCustomerType !== 'Физлицо'">
          <v-text-field
              v-model="changeCustomerCompany"
              label="Название компании"
              required
              :rules="[v => !!v && !!v.trim() || 'Обязательное поле']"
          />
          <v-text-field
              v-model="changeCustomerVat"
              label="ИНН"
              required
              :rules="[v => !!v && !!v.trim() || 'Обязательное поле']"
          />
        </div>
        <v-text-field
            v-model="changeCustomerName"
            label="Фамилия, имя, отчество"
            required
            :rules="[v => !!v && v.trim().split(' ').length > 1 || 'Укажите ФИО']"
        />
        <div v-if="changeCustomerType === 'Физлицо'">
          <v-subheader>Паспорт</v-subheader>
          <v-text-field
              class="col-4 d-inline-block pa-0"
              v-model="changeCustomerPassportSerial"
              label="Серия"
              required
              :rules="[v => !!v && !!v.trim() || 'Обязательное поле']"
          />
          <v-text-field
              class="col-8 d-inline-block pa-0"
              v-model="changeCustomerPassportNumber"
              label="Номер"
              :rules="[v => !!v && !!v.trim() || 'Обязательное поле']"
          />
        </div>
      </v-form>
      <template #actions>
        <v-btn
            color="primary"
            outlined
            @click="updateCustomerCompanyType"
            :loading="loading"
            :disabled="loading"
        >
          Сохранить
        </v-btn>
        <v-btn
            color="primary"
            text
            @click="changeCompanyTypeDialog = false"
        >
          Закрыть
        </v-btn>
      </template>
    </dialog-card>
    <alert-banner v-model="error"/>
  </div>
</template>
<style scoped>
.user-card {
  min-height: 312px;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
<script>
import {mapActions, mapState} from "vuex";
import date from "@/lib/date";
import Pagination from "@/components/Admin/components/Pagination";
import Search from "@/components/Admin/components/Search";
import MultiUpload from "@/components/Admin/components/MultiUpload";
import DialogCard from "@/components/DialogCard";
import PrMenu from "@/components/PrMenu";
import FacilityRepository from "@/repository/facility";
import AlertBanner from "@/components/AlertBanner";
import PrSelect from "@/components/PrSelect";

export default {
  name: "Users",
  components: {Search, Pagination, PrMenu, DialogCard, MultiUpload, AlertBanner, PrSelect},
  data() {
    return {
      date,
      files: [],
      dialog: false,
      multiUploadDialog: false,
      loading: false,
      page: 1,
      customer: {},
      customerPds: {},
      customerInvoices: [],
      customerDocuments: [],
      customerRoles: {},
      query: "",
      facilities: "",
      keyDialog: false,
      key: "",
      tab: 0,
      textConfirmDialog: false,
      textConfirm: "",
      clipboardToast: false,
      invoiceLoading: false,
      documentsLoading: false,
      users: [],
      facilityList: [],
      changeEmailDialog: false,
      changeEmailAddress: "",
      editCustomerDisclaimerDialog: false,
      editCustomerEmailDisclaimerDialog: false,
      editCustomerEmailDisclaimerConfirm: false,
      changeCompanyForbiddenDialog: false,
      changeCompanyTypeDisclaimerDialog: false,
      changeCompanyTypeDialog: false,
      editCustomerDialog: false,
      customerIsNew: true,
      customerType: '',
      customerName: '',
      customerCompany: '',
      customerVat: '',
      customerPassportSerial: '',
      customerPassportNumber: '',
      customerCountry: '',
      customerCity: '',
      customerStreet: '',
      customerEmail: '',
      customerPhone: '',
      customerMarketing: false,
      customerBaseFacility: '',
      changeCustomerPassportNumber: '',
      changeCustomerPassportSerial: '',
      changeCustomerVat: '',
      changeCustomerCompany: '',
      changeCustomerType: '',
      changeCustomerName: '',
      error: '',
    };
  },
  async mounted() {
    await this.loadList();
    this.facilityList = await FacilityRepository.list();
  },
  computed: {
    ...mapState({
      user: state => state.profile.user,
      storeKey: state => state.admin.key,
    }),
  },
  methods: {
    search() {
      this.page = 1;
      return this.loadList();
    },
    saveKey() {
      this.keyDialog = false;
      this.$store.commit("admin/setKey", this.key);
      this.showPds();
    },
    async text(id, facilityCode) {
      this.textConfirm = "";
      let result = await this.sendPin({id, facilityCode});
      if (result.sms) {
        let phone = Object.keys(result.sms)[0];
        if (result.sms[phone].status === "ERROR") {
          this.textConfirm = result.sms[phone].status_text;
        } else {
          this.textConfirm = "Пин-код отправлен успешно";
        }
      } else {
        this.textConfirm = "Произошла ошибка, обратитесь к разработчику";
      }
      this.textConfirmDialog = true;
    },
    editCustomer(item, pds) {
      this.customerBaseFacility = this.facilityList.length ? this.facilityList[0].facility_code : '';
      this.customerIsNew = !item.id;
      this.customerType = item.company_type || 'Физлицо';
      this.customerName = item.firstname;
      this.customerCompany = item.company;
      this.customerVat = item.vat;
      this.customerPassportSerial = pds.data?.passport_serial;
      this.customerPassportNumber = pds.data?.passport_number;
      this.customerCountry = pds.address?.country || "Россия";
      this.customerCity = pds.address?.city || "Санкт-Петербург";
      this.customerStreet = pds.address?.street;
      this.customerEmail = item.email;
      this.customerPhone = item.phone || "+7";
      this.customerMarketing = item.marketing || false;
      this.editCustomerDialog = true;
      setTimeout(() => this.$refs.editCustomerForm.resetValidation(), 0);
    },
    async edit(item) {
      this.invoiceLoading = true;
      this.documentsLoading = true;
      this.dialog = true;
      Object.assign(this.customer, item);
      if (item.id !== this.customerPds.user_id) {
        this.customerPds = {}
      }
      this.customerRoles = this.customer.role || {};
      this.facilities = (this.customerRoles?.receipt?.facility || []).join(",");
      this.customerInvoices = await this.getInvoices({id: item.id});
      this.invoiceLoading = false;
      this.customerDocuments = await this.getDocuments({id: item.id});
      this.documentsLoading = false;
    },
    async showPds() {
      if (!this.storeKey) {
        this.keyDialog = true;
      } else {
        this.loading = true;
        this.customerPds = await this.showUser({id: this.customer.id, data: {key: this.storeKey}});
        this.loading = false;
      }
    },
    async deleteDocument(fileId) {
      await this.removeDocument({id: this.customer.id, fileId});
      this.customerDocuments = await this.getDocuments({id: this.customer.id});
    },
    async addDocuments() {
      const payload = new FormData();
      this.files.forEach(file => payload.append(String(Math.random()), file));
      await this.uploadDocuments({id: this.customer.id, data: payload});
      this.files = [];
      this.customerDocuments = await this.getDocuments({id: this.customer.id});
    },
    async share(facilityCode, invoiceId) {
      let token = await this.getCustomerToken({id: this.customer.id});
      await navigator.clipboard.writeText(`${document.location.origin}/redirect/profile/invoice/${facilityCode}-${invoiceId}?token=${token.token}`);
      this.clipboardToast = true;
    },
    async mask() {
      let token = await this.getCustomerToken({id: this.customer.id});
      document.location = `${document.location.origin}/redirect/profile?token=${token.token}`;
    },
    async changeEmail() {
      this.loading = true;
      const user = this.users?.list?.find(item => item.id === this.customer.id);
      if (user) {
        try {
          await this.editUserEmail({
            id: user.id,
            data: {
              email: this.changeEmailAddress,
            },
          });
          this.customer.email = this.changeEmailAddress;
          user.email = this.changeEmailAddress;
        } catch (e) {
          this.error = e.response.data.errors.email;
        }
      }
      this.changeEmailDialog = false;
      this.changeEmailAddress = null;
      this.loading = false;
    },
    async request() {
      this.loading = true;
      if (this.$refs.form.validate()) {
        const user = this.users?.list?.find(item => item.id === this.customer.id);
        if (user) {
          if (this.customerRoles?.receipt) {
            this.customerRoles.receipt = {facility: this.facilities.split(",")};
          }
          user.role = this.customerRoles;
          Object.keys(user.role).forEach(key => !user.role[key] && delete user.role[key]);
          await this.editUserRoles({
            id: user.id,
            data: {
              role: user.role,
            },
          });
        }
      }
      this.loading = false;
      this.dialog = false;
    },
    async updateCustomer() {
      this.error = ''
      if (this.$refs.editCustomerForm.validate()) {
        this.loading = true;
        const user = this.users?.list?.find(item => item.id === this.customer.id);
        try {
          let customer = await this.editUser({
            data: {
              new: this.customerIsNew,
              facility_code: this.customerBaseFacility,
              company_type: this.customerType === 'Физлицо' ? '' : this.customerType,
              firstname: this.customerName,
              company: this.customerCompany,
              vat: this.customerVat,
              passport_serial: this.customerPassportSerial,
              passport_number: this.customerPassportNumber,
              country: this.customerCountry,
              city: this.customerCity,
              street: this.customerStreet,
              email: this.customerEmail,
              phone: this.customerPhone,
              marketing: this.customerMarketing,
            },
          });
          if (user) {
            Object.keys(customer).forEach(key => {
              user[key] = customer[key];
              if (!this.customerIsNew) {
                this.customer[key] = customer[key];
              }
            });
          }
          this.customerPds = {}
          this.editCustomerDialog = false;
          this.error = ''
        } catch (e) {
          this.error = e.response.data.message;
        } finally {
          this.loading = false;
        }
      }
    },
    async loadList() {
      this.loading = true;
      this.users = await this.loadUsers({
        page: this.page,
        query: this.query,
      });
      this.loading = false;
    },
    changeCompanyType() {
      if (this.user.role.admin || this.user.role.receipt_manager) {
        this.changeCompanyTypeDisclaimerDialog = true;
        this.changeCustomerPassportNumber = this.customerPassportNumber;
        this.changeCustomerPassportSerial = this.customerPassportSerial;
        this.changeCustomerVat = this.customerVat;
        this.changeCustomerCompany = this.customerCompany;
        this.changeCustomerType = this.customerType;
        this.changeCustomerPassportNumber = this.customerPassportNumber;
        this.changeCustomerPassportSerial = this.customerPassportSerial;
        this.changeCustomerVat = this.customerVat;
        this.changeCustomerCompany = this.customerCompany;
        this.changeCustomerType = this.customerType;
        this.changeCustomerName = this.customerName;
      } else {
        this.changeCompanyForbiddenDialog = true;
      }
    },
    async updateCustomerCompanyType() {
      this.error = ''
      if (this.$refs.editCustomerCompanyTypeForm.validate()) {
        this.loading = true;
        const user = this.users?.list?.find(item => item.id === this.customer.id);
        if (user) {
          try {
            const customer = await this.editCompanyType({
              id: user.id,
              data: {
                company_type: this.changeCustomerType === 'Физлицо' ? '' : this.changeCustomerType,
                firstname: this.changeCustomerName,
                company: this.changeCustomerType === 'Физлицо' ? '' : this.changeCustomerCompany,
                vat: this.changeCustomerType === 'Физлицо' ? '' : this.changeCustomerVat,
                passport_serial: this.changeCustomerType === 'Физлицо' ? this.changeCustomerPassportSerial : '',
                passport_number: this.changeCustomerType === 'Физлицо' ? this.changeCustomerPassportNumber : '',
              },
            });
            Object.keys(customer).forEach(key => {
              user[key] = customer[key];
              this.customer[key] = customer[key];
              this.customerPassportNumber = this.changeCustomerPassportNumber;
              this.customerPassportSerial = this.changeCustomerPassportSerial;
              this.customerVat = this.changeCustomerVat;
              this.customerCompany = this.changeCustomerCompany;
              this.customerType = this.changeCustomerType;
              this.customerPassportNumber = this.changeCustomerPassportNumber;
              this.customerPassportSerial = this.changeCustomerPassportSerial;
              this.customerVat = this.changeCustomerVat;
              this.customerCompany = this.changeCustomerCompany;
              this.customerType = this.changeCustomerType;
              this.customerName = this.changeCustomerName;
            });
            this.changeCompanyTypeDialog = false;
          } catch (e) {
            this.error = e.response.data.errors.email;
          }
        }
        this.loading = false;
      }
    },
    ...mapActions({
      loadUsers: "admin/listUsers",
      editUser: "admin/editUser",
      editCompanyType: "admin/editCompanyType",
      editUserRoles: "admin/editUserRoles",
      editUserEmail: "admin/editUserEmail",
      showUser: "admin/showUser",
      getInvoices: "admin/getInvoices",
      getDocuments: "admin/getDocuments",
      removeDocument: "admin/removeDocument",
      uploadDocuments: "admin/saveDocuments",
      getCustomerToken: "admin/getToken",
      sendPin: "admin/sendPin",
    }),
  },
};
</script>
